<template>
    <page
        id="users-list"
        :title="is_realtor ? $root.translate('Client:::Clients', {}, 2) : $root.translate('User:::Users', {}, 2)"
        :status="page_status"
        :waiting="loading && !data_version"
        :is-root="true"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                <span v-if="is_realtor">{{ $root.translate('Client:::Clients', {}, 2) }}</span>
                <span v-else>{{ $root.translate('User:::Users', {}, 2) }}</span>
                <default-button
                    :title="is_realtor ? $root.translate('New Client') : $root.translate('New User')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>

        <stat-counters
            v-if="is_at_least_administrator"
            :counters="stats"
            class="mb-8"
        />

        <data-table
            id="users-list"
            :entity-name="is_realtor ? 'client:::clients' : 'user:::users'"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.uuid)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-dropdown
                        v-if="is_at_least_administrator"
                        id="filter"
                        name="filter"
                        :value="state.filter"
                        :options="filters"
                        empty-value=""
                        :empty-label="translate('All users')"
                        :required="false"
                        @input="filter('role_or_status', $event)"
                    />
                    <form-dropdown
                        v-else
                        id="filter"
                        name="filter"
                        :value="state.filter"
                        :options="statii"
                        :empty-value="null"
                        :empty-label="translate('Any Status')"
                        :required="false"
                        @input="filter('status', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <div class="text-small">
                    <mailto-link
                        :value="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </div>
                <div class="text-small text-gray">
                    {{ translate("Last Login") }}:
                    <span v-if="row.last_login_at">
                        {{ row.last_login_at | nibnut.date }} @ {{ row.last_login_at | nibnut.date("HH:mm") }}
                    </span>
                    <span v-else>{{ translate("Never") }}</span>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    {{ row.name }}
                    <div v-if="!!row.invitation_expires_at" class="text-gray text-small">
                        <span v-if="invitation_expired(row)">{{ $root.translate("Invitation expired on") }}</span>
                        <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                        {{ row.invitation_expires_at | nibnut.date("YYYY-MM-DD @ HH:mm") }}
                        <default-button
                            :title="$root.translate('Re-send invitation')"
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="resend_invitation(row)"
                        >
                            <open-icon glyph="share" />
                        </default-button>
                    </div>
                </div>
                <span v-else-if="field === 'email'">
                    <mailto-link
                        :value="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </span>
                <span v-else-if="field === 'last_login_at'">
                    <span v-if="row.last_login_at">
                        {{ row.last_login_at | nibnut.date }} @ {{ row.last_login_at | nibnut.date("HH:mm") }}
                    </span>
                    <span v-else>{{ translate("Never") }}</span>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />

        <modal-dialog
            id="invite-user"
            :dismissable="!sending_invitation"
            :show.sync="inviting"
        >
            <template v-slot:title>
                <span v-if="is_realtor" class="h5">{{ $root.translate("Invite Client") }}</span>
                <span v-else class="h5">{{ $root.translate("Invite User") }}</span>
            </template>
            <base-form
                :has-required-fields="true"
                @submit.prevent="send_invitation"
            >
                <div class="columns">
                    <div
                        :class="{ 'col-6': !is_realtor, 'col-12': is_realtor }"
                        class="column col-md-12 mb-2"
                    >
                        <form-input
                            id="email"
                            type="email"
                            name="email"
                            v-model="invitee.email"
                            :required="true"
                            :error="has_error('email')"
                        >
                            <template v-slot:label>{{ $root.translate("Email") }}</template>
                        </form-input>
                    </div>
                    <div v-if="!is_realtor" class="column col-6 col-md-12 mb-2">
                        <form-select
                            id="role"
                            name="role"
                            v-model="invitee.role"
                            :data-source="filtered_roles"
                            :required="true"
                            :error="has_error('role')"
                        >
                            <template v-slot:label>{{ $root.translate("Role") }}</template>
                        </form-select>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-6 col-lg-12 mb-2">
                        <form-input
                            id="first_name"
                            name="first_name"
                            v-model="invitee.first_name"
                            :required="false"
                            :error="has_error('first_name')"
                        >
                            <template v-slot:label>{{ $root.translate("First Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-lg-12 mb-2">
                        <form-input
                            id="last_name"
                            name="last_name"
                            v-model="invitee.last_name"
                            :required="false"
                            :error="has_error('last_name')"
                        >
                            <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                        </form-input>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-12">
                        <form-listing-link-input
                            id="linked_listing_ids"
                            v-model="invitee.listing_ids"
                        >
                            <template v-slot:label>{{ $root.translate("Properties") }}</template>
                        </form-listing-link-input>
                    </div>
                    <div class="column col-12">
                        <invitation-expiries
                            id="expires_in"
                            name="expires_in"
                            v-model="invitee.expires_in"
                            :required="true"
                            :error="has_error('expires_in')"
                        />
                    </div>
                </div>
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :waiting="sending_invitation"
                        :disabled="sending_invitation"
                        color="primary"
                        @click.prevent="send_invitation"
                    >
                        {{ $root.translate("Send Invitation") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { is_page, is_remote_data_table_source, crud_utilities, is_user_inviter } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import {
    DefaultButton,
    MailtoLink,
    ModalDialog,
    BaseForm,
    FormInput,
    FormSelect,
    FormDropdown,
    InvitationExpiries,
    OpenIcon
} from "@/nibnut/components"

import {
    StatCounters,
    FormListingLinkInput
} from "@/custom/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, is_user_inviter, addl_profile_utilities],
    components: {
        DefaultButton,
        MailtoLink,
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        FormDropdown,
        InvitationExpiries,
        OpenIcon,
        StatCounters,
        FormListingLinkInput
    },
    methods: {
        reset () {
            if(!this.data_version && !!this.$route.query) {
                this.set_state_values({
                    filter_on: this.$route.query.filter_on || null,
                    filter: this.$route.query.filter || null
                })
            }
            this.refresh()
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        record_create (entity) {
            this.record_shell(entity).then(record => {
                this.invitee = {
                    ...record,
                    listing_ids: [],
                    expires_in: this.app_context.settings.default_invitation_delay
                }
                this.inviting = true
            })
        },
        invitation_expired (row) {
            if(!!row && !!row.invitation_expires_at) return this.$dayjs(row.invitation_expires_at).isBefore(this.$dayjs())
            return false
        },
        send_invitation () {
            this.sending_invitation = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: this.invitee
            }).then(record => {
                this.inviting = false
                this.invitee = {}
                this.record_edit(this.entity, record.uuid)
            }).catch(this.receive_error).then(() => {
                this.sending_invitation = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
        },
        statii () {
            return [
                { id: "active", name: this.translate("Active") },
                { id: "inactive", name: this.translate("Inactive") }
            ]
        },
        filters () {
            return [
                ...this.filtered_roles,
                ...this.statii
            ]
        },
        stats () {
            const users = 10
            const online = 2
            return [
                { id: "total-users", title: this.translate("User:::Users", {}, users), count: users, glyph: "user" },
                { id: "online-users", title: this.translate("User Online:::Users Online", {}, online), count: online, glyph: "user-clock" }
            ]
        }
    },
    data () {
        return {
            entity: "user",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                email: { label: "Email", sort: null, type: "alpha" },
                last_login_at: { label: "Last Login", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            inviting: false,
            invitee: {},
            sending_invitation: false
        }
    }
}
</script>

<style lang="scss">
#users-list {
    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
